<div class="main_header">
  <div class="left-header">
    <a (click)="checkBack()">
      <img class="logo" src="assets/images/ic_logo.svg">
    </a>
    <a *ngIf="shouldShow()" class=" headermenuBtn"><img src="../../../assets/images/ic_menu.svg" (click)="sideToggle()"></a>
  </div>

  <div class="right-header">
  <div *ngIf="(localData?.userData) ? (localData?.userData.isPlanActive !=0) : (localData?.isPlanActive != 0)" class="d-flex align-items-center">
    <a *ngIf="(localData?.userData) ? (localData?.userData.isPlanActive !=0) : (localData?.isPlanActive != 0)" class="faq" [routerLink]="'/web-faq'">
      FAQs
    </a>
    <span class="cv" *ngIf="(localData?.userData) ? (localData?.userData.isPlanActive !=0) : (localData?.isPlanActive != 0)">
    <img  [routerLink]="'/notifications'" class="noti pointer" src="../../../assets/images/ic_notification.svg">
    <div class="pulse1 " *ngIf="newNotificaton"></div>
  </span>

    <a class="side_img mr-3">
      <div class="commonRow" ngbDropdown [autoClose]="true" #myDrop="ngbDropdown">
        <div class="addGD-Wrap  form-control date-icon pointer" id="dropdownBasic2" ngbDropdownToggle>
       
          <img *ngIf="localData?.userData ? !localData?.userData?.profilePic : !localData?.profilePic" src="assets/images/ic_add_image.svg">
          <img *ngIf="localData?.userData ? localData?.userData?.profilePic : localData?.profilePic" [src]="imageUrl + (localData?.userData ? localData?.userData?.profilePic : localData?.profilePic)">
          <label class="pointer">{{ (localData?.userData) ? (localData?.userData.fullName) : (localData?.fullName)}}</label>
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="grlistDropDwon">
          <ul class="autoComplete m-0">
            <li *ngIf="localData?.userData?.isPlanActive !=0" class="menu" (click)="myDrop.close();" [routerLink]="'/my-profile'">
              <label class="input-helper hideCheckBorder">View profile</label>
            </li>
            <li *ngIf="localData?.userData?.isPlanActive !=0" class="menu" (click)="myDrop.close();">
              <label class="input-helper hideCheckBorder" [routerLink]="'/change-password'">Change password</label>
            </li>
            <li *ngIf="localData?.userData?.isPlanActive !=0" class="menu d-flex">
              <label class="input-helper hideCheckBorder">Notifications</label>
              <label class="switch" >
                <input type="checkbox" [(ngModel)]="localStorage.userData.notificationEnabled" (click)="notificationOnoff()" >
                <span class="slider round"></span>
              </label>
            </li>
            <li *ngIf="localData?.userData?.isPlanActive !=0" class="menu" (click)="myDrop.close();" [routerLink]="'/web-terms-conditions'">
              <label class="input-helper hideCheckBorder">Terms & conditions</label>
            </li>

            <li *ngIf="localData?.userData?.isPlanActive !=0" class="menu" (click)="myDrop.close();" [routerLink]="'/web-privacy-policy'">
              <label class="input-helper hideCheckBorder">Privacy policy</label>
            </li>

            <li *ngIf="localData?.userData?.isPlanActive !=0" class="menu" (click)="myDrop.close();openPopup(localData?.userData?.isPrimaryMember  == 1 ? deleteAccountPrimary : deleteAccount)">
              <label class="input-helper hideCheckBorder">Delete account</label>
            </li>

            <li class="menu" (click)="myDrop.close();logout()">
              <label class="input-helper hideCheckBorder">Logout</label>
            </li>
          </ul>
        </div>
      </div>
    </a>
  </div>
</div>
</div>

  <ng-template #deleteAccount>
    <div class="modal-header">
      <h4 class="modal-title w-100"> Delete your account </h4>
      <span><i><img src="assets/images/ic_emojie.svg"></i> {{(localData?.userData)?(localData?.userData?.fullName):(localData?.fullName)}}, sorry to see you go! </span>
    </div>
    <form [formGroup]="form">
      <div class="modal-body">
        <p> When you delete your account, it will remove all your information from our database. This cannot be undone.
        </p>
        <span class="blueText"> To confirm this, enter your password and proceed. </span>

        <div class="form-group">
          <label> Password </label>
          <input [type]="passwordType" name="password" formControlName="password" placeholder="Enter password"
            autocomplete="off">
          <div class="text-danger" *ngIf="form.controls['password'].hasError('required') && showError">
            *Please enter password.
          </div>
          <div class="text-danger"
            *ngIf="showError&&form.controls.password.errors?.minlength || showError&&form.controls.password.errors?.maxlength || form.controls['password'].hasError('pattern') && showError">
            *Password must be 8 to 16 alphanumeric upper and lower case characters in length and contain at least one
            special character.
          </div>
          <a class="showPass" (click)="togglePassword()">
            <img [src]="passwordType == 'text'? 'assets/images/ic_open_eye.svg' :'assets/images/ic_close_eye.svg'" /></a>
        </div>
      </div>
      <div class="modal-footer ">
        <button type="button" class="delete_account" (click)="delete()"> Delete account </button>
        <button type="button" class="cancel_btn" data-dismiss="modal" (click)="close()"> Cancel </button>
      </div>
    </form>
  </ng-template>
  
  <ng-template #deleteAccountPrimary>
    <div class="modal-header">
      <h4 class="modal-title w-100"> Delete your account </h4>
      <span><i><img src="assets/images/ic_emojie.svg"></i> {{(localData?.userData)?(localData?.userData?.fullName):(localData?.fullName)}}, sorry to see you go! </span>
    </div>
    <form [formGroup]="form">
      <div class="modal-body">
        <p> When you delete your account, it will remove all your information from our database. This cannot be undone.
        </p>
        <span class="blueText"> To confirm this, enter your password and proceed. </span>

        <div class="form-group">
          <label> Password </label>
          <input [type]="passwordType" name="password" formControlName="password" placeholder="Enter password"
            autocomplete="off">
          <div class="text-danger" *ngIf="form.controls['password'].hasError('required') && showError">
            *Please enter password.
          </div>
          <div class="text-danger"
            *ngIf="showError&&form.controls.password.errors?.minlength || showError&&form.controls.password.errors?.maxlength || form.controls['password'].hasError('pattern') && showError">
            *Password must be 8 to 16 alphanumeric upper and lower case characters in length and contain at least one
            special character.
          </div>
          <a class="showPass" (click)="togglePassword()">
            <img [src]="passwordType == 'text'? 'assets/images/ic_open_eye.svg' :'assets/images/ic_close_eye.svg'" /></a>
        </div>
      </div>
      <div class="modal-footer ">
        <button type="button" class="delete_account" (click)="delete()"> Delete account </button>
        <button type="button" class="cancel_btn" data-dismiss="modal" (click)="close()"> Cancel </button>
      </div>
    </form>
  </ng-template>