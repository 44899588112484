import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-internal-header',
  templateUrl: './internal-header.component.html',
  styleUrls: ['./internal-header.component.css']
})
export class InternalHeaderComponent implements OnInit {
  urlCheck:any
  constructor(private router: Router) {
    // console.log('url', router.url)
    this.router.events.subscribe((val) => {
     if (val instanceof NavigationEnd) {
        // console.log(val.url)
        if((val.url || '').includes('?')){
          //  console.log('treu', val.url.split('?', 1))
          let data = val.url.split('?', 1)
           this.urlCheck = data[0]
          //  console.log('treu', this.urlCheck)
        }else{
          this.urlCheck = val.url
        }
        
      
      }   
  });
   }

  ngOnInit(): void {
    console.log('internal');
  }

}
