export const apiUrl = {
	_adminLogin: "api/v1/user/login",
	_forgotPassword: "api/v1/user/forgotPassword",
	_resetPassword: "api/v1/user/resetPassword",
	_register: 'api/v1/user/signUp',
	_verifyEmail: 'api/v1/user/verifyEmail',
	_resendOtp: 'api/v1/user/resendEmailForVerification',
	_userType: 'api/v1/user/userType',
	_user: 'api/v1/user',
	_userById: 'api/v1/user/userDetail',
	_getDesignations: 'api/v1/user/getDesignations',
	_defaultSetting: 'api/v1/user/defaultSetting',
	_verifyForgetPasswordOtp: 'api/v1/user/verifyForgetPasswordOtp',
	_logOut: 'api/v1/user/logout',
	_adminSetting: 'api/v1/user/adminSetting',
	_changePassword: 'api/v1/user/changePassword',
	_getPlans: 'api/v1/licenses/getSubscriptionPlan',
	_getSubscribedPlans: 'api/v1/licenses/getUserSubscriptionPlan',
	_buyPlans: 'api/v1/licenses/buySubscriptionPlan',
	_vat: 'api/v1/user/verifyVAT',

	_getNotification: 'api/v1/notifications',
	_putNotification: 'api/v1/notifications/readAll',
	_putNoti: 'api/v1/user/notificationEnabled',
	// common api 
	_uploadPic: 'api/v1/common/getSignedURL',
	_countryList: 'api/v1/common/countries?limit=249&skip=0',

	//insights
	_insights: 'api/v1/insights',
	_sourceCountry: 'api/v1/insights/sourceCountry',
	_sourceCountries: 'api/v1/common/sourceCountries',
	

	_destinationCountry: 'api/v1/insights/destinationCountry',
	_destinationCountries: 'api/v1/common/destinationCountries',

	_insightResult: 'api/v1/insights/Result',
	_getResult: 'api/v1/results',
	_insightSingleResult: 'api/v1/results/singleResult',
	_getSharedResult: 'api/v1/results/Detail',
	_calculation: 'api/v1/insights/grossAmount',
	_sharedCalculation: 'api/v1/insights/grossAmountShare',
	_resultfav: 'api/v1/insights/resultFavorite',
	_getGenerateCheck: 'api/v1/results/message',

	//team
	_getTeam: 'api/v1/team',
	_addUser: 'api/v1/team',
	_editRole: 'api/v1/team/editRole',
	_disableUser: 'api/v1/team/disable',
	_purchaseHistory: 'api/v1/user/purchaseHistory',
	_changePrimaryMember: 'api/v1/team/primaryMember',

	//licenese
	_getLicense: 'api/v1/licenses/getAllLicenses',
	_assignLicense: 'api/v1/licenses/assignLicense',
	_downloadInvoice: 'api/v1/user/exportInvoice',

}
