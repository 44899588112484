<div class="insight-result">
    <div class="container">
        <div class="top_bar_head">
            <ul class="socialmedia">
                <li>
                    <button type="submit" class="calculate_btn" (click)="openPopup(calculatePopup)"> Calculate
                    </button>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="singnalBox pointer" (click)="openModal(whatsPopup)">
                    <div class="lightimg">
                        <img *ngIf="resultDetails?.level == 3" src="../../../assets/images/ic_green_lights.svg" alt="">
                        <img *ngIf="resultDetails?.level == 1" src="../../../assets/images/ic_red_lights.svg" alt="">
                        <img *ngIf="resultDetails?.level == 2" src="../../../assets/images/ic_yellow_lights.svg" alt="">
                    </div>
                    <a class="lightInfo"> What’s this? </a>
                </div>
            </div>
            <div class="col-md-8">
                <div class="blueBox">
                    <div class="whiteBoxIddetails ">
                        <button type="submit" class="ukSingaporeBtn">{{allData?.summary[0]?.insightResult}} to
                            {{allData?.summary[1]?.insightResult}}</button>
                        <div class=" d-flex justify-content-between">
                            <p>Result Id: <strong> {{resultDetails?.appResultId}} </strong></p>
                            <p>Generated on: <strong *ngIf="allData?.createdAt"> {{utcToLocal(allData?.createdAt)}} </strong></p>
                            <p *ngIf="allData?.expiryDate != null">Valid till: <strong> {{utcToLocal(allData?.expiryDate)}} </strong></p>
                        </div>
                    </div>
                    <h5 class="subHeading"> Result </h5>
                    <div class="whiteBox" [class.greenBorder]="resultDetails?.level == 3"
                        [class.yellowBorder]="resultDetails?.level == 2" [class.redBorder]="resultDetails?.level == 1">
                        <div class="greenbox greentbox" [class.green]="resultDetails?.level == 3"
                            [class.yellow]="resultDetails?.level == 2" [class.red]="resultDetails?.level == 1" >
                            <div [innerHTML]="resultDetails?.heading ? (resultDetails?.heading | safeHtml):''"></div>
                            <!-- [innerHTML]="next(resultDetails?.heading || '')" -->
                        </div>
                            <ul>
                                <li>
                                    <p><strong> Based on: </strong></p>
                                    <!-- <p> {{resultDetails?.BasedOn}} </p> -->
                                    <!-- <div style="display:block" [innerHTML]="next(resultDetails?.BasedOn || '')"></div> -->
                                    <div style="display:block" [innerHTML]="resultDetails?.BasedOn ? (resultDetails?.BasedOn | safeHtml) : ''"></div>
                                </li>
                                <li >
                                    <i class="action" *ngIf="resultDetails?.requiredIcon == 1"><img src="../../../../assets/images/ic_action.svg" alt=""> </i>
                                    <p><strong> Action required: </strong></p>
                                    <!-- <p> {{resultDetails?.ActionRequired}} </p> -->
                                    <!-- <div style="display:block" [innerHTML]="next(resultDetails?.ActionRequired || '')"></div> -->
                                    <div style="display:block" class="" [innerHTML]="resultDetails?.ActionRequired ? (resultDetails?.ActionRequired | safeHtml) : ''"></div>
                                </li>
                                <li *ngIf="resultDetails?.YouCanExpect">
                                    <p><strong> You can expect: </strong></p>
                                    <!-- <p> {{resultDetails?.YouCanExpect}} </p> -->
                                    <!-- <div style="display:block" [innerHTML]="next(resultDetails?.YouCanExpect || '')"></div> -->
                                    <div style="display:block" class="" [innerHTML]="resultDetails?.YouCanExpect ? (resultDetails?.YouCanExpect | safeHtml) : ''"></div>
                                </li>
                                <li *ngIf="resultDetails?.FurtherNotes">
                                    <p> <strong> Further notes: </strong> </p>
                                    <!-- <p> {{resultDetails?.FurtherNotes}} </p> -->
                                    <!-- <div style="display:block" [innerHTML]="next(resultDetails?.FurtherNotes || '')"></div> -->
                                <div style="display:block" class="" [innerHTML]="resultDetails?.FurtherNotes ? (resultDetails?.FurtherNotes | safeHtml) : ''"></div>
                                </li>
                                <li *ngIf="resultDetails?.links">
                                    <p> <strong> Useful links: </strong> </p>
                                    <!-- <a *ngIf="resultDetails?.links" [href]="resultDetails?.links" target="_blank"
                                        class="link" [innerHTML]="next(resultDetails?.links || '')"></a> -->
                                        <a *ngIf="resultDetails?.links" [href]="resultDetails?.links  ? (resultDetails?.links  | safeHtml) : ''" target="_blank"
                                    class="link " [innerHTML]="resultDetails?.links ? (resultDetails?.links  | safeHtml) : ''"></a>
                                    <!-- <div style="display:block" [innerHTML]="next(resultDetails?.links || '')"></div>     -->
                                </li>
                                <li *ngIf="resultDetails?.example">
                                    <p> <strong> Example: </strong> </p>
                                    <!-- <p> {{resultDetails?.example}} </p> -->
                                    <!-- <div style="display:block" [innerHTML]="next(resultDetails?.example || '')"></div> -->
                                <div style="display:block" class="" [innerHTML]="resultDetails?.example ? (resultDetails?.example | safeHtml) : ''"></div>
                                </li>
                            </ul>
                    </div>
                    <div class="accordionRules">
                        <div class="accordion">
                            <div class="accordion-head active position-relative">
                                <h3 class="title" (click)="expandToggle(1)" data-toggle="collapse" data-target="#1"
                                    aria-expanded="collapsed[1]" aria-controls="1"> Rules
                                </h3>
                                <i [class]="collapsed[1] ? 'bar' : 'bar bar-after'" aria-hidden="true"
                                    class="expand-icon pointer" (click)="expandToggle(1)" data-toggle="collapse"
                                    data-target="#1" aria-expanded="collapsed[1]"></i>
                                <div id="1" [class]="collapsed[1] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
                                    data-parent="#accordion">

                                    <div class="card-body">
                                        <ul class="accordionlist">
                                            <li *ngFor="let item of allData?.summary">
                                                <span class="yellowCircle"><img
                                                        src="../../../../assets/images/ic_yellow-circle.svg"
                                                        alt=""></span>
                                                <h6> {{item?.insight}} </h6>
                                                <p> {{item?.insightResult}} </p>
                                                <span class="arrowupBlue"><img
                                                        src="../../../../assets/images/ic_arrowupblue.svg"
                                                        alt=""></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="userDetail?.contactName" class="col-md-3 mb-4">
                <div class="rightBox">
                    <p> Need help with the tax on this transaction? Connect to your teammate </p>
                    <div class="noah-williams">
                        <div class="user-details">
                            <div class="userpic"> {{userDetail?.contactName.charAt(0)}} </div>
                            <!-- <img class="userpic" *ngIf="userDetail?.profilePic" [src]="imageUrl + userDetail?.profilePic" alt=""> -->
                            <div class="userInfo">
                                <h6 class="userName"> {{userDetail?.contactName}} </h6>
                                <p class="email"> {{userDetail?.contactEmail}} </p>
                            </div>
                        </div>
                        <button type="submit" class="calculate_btn email" (click)="sendMail()"> Email </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #whatsPopup>
    <div class="modal-header">
        <h4 class="modal-title text-center"> What do the traffic light colours mean? </h4>
        <a class="ic_cross" (click)="closeModal()"><img src="../../../assets/images/ic_cross_big.svg"></a>
    </div>
    <div class="modal-body">
        <div class="redpanel">
            <h6><i class="ic_red"> </i> Red - Pause and assess the risk </h6>
            <p> Used when: </p>
            <ul>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> Withholding tax applies under
                    local legislation in your customer country; and </li>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> The rate of withholding tax
                    is 25% or more; OR </li>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> Withholding tax applies under
                    local legislation in your customer country BUT a TAX TREATY decreases the rate of the withholding
                    tax AND there is uncertainty about how the local tax authorities interpret the law; OR</li>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> Your transaction will be
                    taxable income in your customer country </li>
            </ul>
        </div>

        <div class="yellowpanel">
            <h6><i class="ic_yellow"> </i> Amber - Proceed with caution </h6>
            <p> Used when: </p>
            <ul>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> Withholding tax applies under
                    local legislation in your customer country; and</li>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> The rate of withholding tax
                    is 15% or more; OR</li>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> Withholding tax applies under
                    local legislation in your customer country BUT a TAX TREATY decreases the rate of the withholding
                    tax AND you need to take an action to get that lower rate;</li>
            </ul>
        </div>

        <div class="greenpanel">
            <h6><i class="ic_green"> </i> Green - Go ahead </h6>
            <p> Used when: </p>
            <ul>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> No withholding tax according
                    to the local legislation in your customer country;</li>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> There is withholding tax, but
                    it is 14% or less, the local legislation is clearly applied, and you don't need to take any action;
                </li>
                <li><i class="ic_check"><img src="../../../assets/images/ic_tick.svg"></i> Your transaction isn't going
                    to be taxable income in your customer country</li>
            </ul>
        </div>
    </div>
</ng-template>
<ng-template #calculatePopup>
    <div class="modal-header">
        <a class="back_button" (click)="clear()"><img src="../../../assets/images/ic_back.svg"><span> Back
            </span></a>
        <h4 class="modal-title w-100 "> Calculate </h4>
        <p> Enter the transaction amount and check the total tax amount as per the generated result </p>
    </div>
    <div class="modal-body">
        <div class="whiteBoxIddetails lightgreen">
            <button type="submit" class="ukSingaporeBtn">{{allData?.summary[0]?.insightResult}} to
                {{allData?.summary[1]?.insightResult}}</button>
            <div class=" d-flex justify-content-between">
                <p>Result Id: <strong style="display: block;"
                    > {{resultDetails?.appResultId}} </strong></p>
                <p *ngIf="allData?.expiryDate != null">Valid till: <strong style="display: block;"> {{allData?.expiryDate | date:'dd MMM, yyyy'}} </strong></p>
            </div>
            
        </div>
        <!-- <div class="whtappliesBtn" [innerHTML]="next(resultDetails?.heading || '')"></div> -->
        <div class="whtappliesBtn" [innerHTML]="resultDetails?.heading ? (resultDetails?.heading | safeHtml):''"> {{resultDetails?.heading}} </div>
        
        <div class=" form-group">
            <label>Enter your desired amount <i class="info_icon"
                    (click)="infoPopup(infopopup,'(amount you wish to receive after tax withholding is made)','Desired Amount')"><img
                        src="../../../../assets/images/ic_info.svg" alt=""></i></label>
            <div class="input-group mb-2 mr-sm-2 mb-sm-0 currency-selector">
                <div class="commonRow" ngbDropdown [autoClose]="true" #myDrop="ngbDropdown">
                    <div class="addGD-Wrap  form-control date-icon pointer" id="dropdownBasic2" ngbDropdownToggle>
                        <span class="selectItem" [class.color_text]="curuncy"> {{ curuncy || ''}}</span>
                        <span class="down-icon"></span>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="grlistDropDwon">
                        <div class="searchBox">
                            <input minlength="2" [(ngModel)]="searchTerm" type="text" placeholder="Search..."
                                (keyup)="getCountryList()">
                            <a class="closeSearch search"></a>
                        </div>
                        <ul class="autoComplete">
                            <li class="checkbox " *ngFor="let data of filteredOptions; let i = index"
                                (click)="myDrop.close();selectCurrency(data);">
                                <div class="checkBoxWrap">
                                    <label class="input-helper hideCheckBorder">
                                        <span class="name">({{data?.name}})</span> <span class="symbol"> {{data?.currencySymbol}}</span> </label>
                                </div>
                            </li>
                        </ul>
                        <div class="No_found" *ngIf="filteredOptions?.length==0">
                            No matching results
                        </div>
                    </div>
                </div>
                <input type="text" class="form-control currency-amount" [maxLength]="15" (keypress)="numberOnly($event)"
                    [(ngModel)]="grossAmount" id="inlineFormInputGroup" placeholder="Please enter amount">
            </div>
            <div class="text-danger" *ngIf="!grossAmount && curuncy && showError">
                *Please enter amount.
            </div>
            <div class="text-danger" *ngIf="!curuncy && grossAmount && showError">
                *Please select currency.
            </div>
            <div class="text-danger" *ngIf="!curuncy && !grossAmount && showError">
                *Please select currency.
            </div>
        </div>
        <button *ngIf="!calculatedData" type="button" class="calculateBtn" data-dismiss="modal"
            (click)="calculateData()"> Calculate
        </button>

        <div *ngIf="calculatedData" class="calculatedTotal">
            <h6 class="text-left"> Calculated tax impact </h6>
            <ul>
                <li>
                    <p class="priceName"> Gross amount</p>
                    <p class="price"> {{calculatedData?.symbol}}{{calculatedData?.grossAmount}} </p>
                </li>

                <li>
                    <p class="priceName" [innerHTML]="calculatedData?.Results?.heading ? (calculatedData?.Results?.heading | safeHtml):''">{{calculatedData?.Results?.heading}}</p>
                    <!-- <p class="priceName" [innerHTML]="next(calculatedData?.Results?.heading || '')"></p> -->
                    <p class="price"> {{calculatedData?.symbol}}{{calculatedData?.deduction}} </p>
                </li>

                <li>
                    <p class="priceName">Net amount</p>
                    <p class="price"> {{calculatedData?.symbol}}{{calculatedData?.netAmount}} </p>
                </li>

            </ul>
        </div>
    </div>
    <div *ngIf="calculatedData" class="modal-footer">
        <button type="button" class="calculateBtn calculateAgainBtn" data-dismiss="modal" (click)="calculatedData = ''">
            Calculate again
        </button>
    </div>
</ng-template>
<ng-template #infopopup>
    <div class="modal-body text-center infopopup">
        <h6 class="mt-0"> {{headTxt}} </h6>
        <p> {{infoTxt}}</p>
        <button type="submit" class="btn text-blue" (click)="modalRef1.hide()"> Got It </button>
    </div>
</ng-template>