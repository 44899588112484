import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-buy-license-mobile',
  templateUrl: './buy-license-mobile.component.html',
  styleUrls: ['./buy-license-mobile.component.css']
})
export class BuyLicenseMobileComponent implements OnInit {
  userId;
  accessToken;
  userdata;
  constructor(
    private route : ActivatedRoute,
    private router : Router,
    private api: AdminService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userId = params.userId,
      this.accessToken = params.token
    });
    this.getUserData()
  }
  // add
  getUserData() {
    const data = {
      id: this.userId,
    }
    this.api.getData(apiUrl._userById, data).subscribe((res) => {
      if(res.data) {
        this.userdata = res.data;
        this.userdata['accessToken'] = this.accessToken;
        localStorage.setItem('userData', JSON.stringify({ userData: res.data }));
        this.router.navigate(["/license/buy"],{ queryParams: { 'deeplink': 'https://app.taxcanary.com/login_view' }});
      }
    })

  }

}
