import { Injectable } from "@angular/core";

@Injectable()
export class DeeplinkService {
    constructor() { }


    deeplink(url) {
        console.log(url)
        let ua = navigator.userAgent.toLowerCase();
        let isAndroid = ua.indexOf("android") > -1; // android check
        let isIphone = ua.indexOf("iphone") > -1; // ios check
        if(isAndroid || isIphone) {
            let app = {
                launchApp: function () {
                    setTimeout(function () {
                        //  console.log(url)
                        window.location.href = url;
                    }, 25);
                    window.location.href = url; //which page to open(now from mobile, check its authorization)
                },
                openWebApp: function () {
                    window.location.href = url;
                }
                // console.log(window.location.href)
            };
            app.launchApp();
            window.location.href == url ? 'succes' : 'fail';
        }
    }
}