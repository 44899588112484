<!-- START SIDEBAR-->
<div *ngIf="toShow()" class="sidebar_for_mob" (click)="toggleMob()"></div>
<nav class="page-sidebar" id="sidebar">
  <a class="menu_btn "><img src="../../../assets/images/ic_menu.svg" (click)="sideToggle()"></a>
  <ul class="sidebar">
    <li routerLinkActive="active" [routerLink]="localData?.isPlanActive !=0 ? '/tax-guide' : '/plan/buy'"
      ngbTooltip="Tax guide">
      <a>
        <!-- <img src="../../../assets/images/ic_tax_guide_inactive.svg" alt=""> -->
        <i class="sidebar-item-icon tax_guide"> </i>
        <span class="nav-label"> Tax guide </span>
      </a>
    </li>
    <li routerLinkActive="active" [routerLink]="localData?.isPlanActive !=0 ? '/my-result' : '/plan/buy'"
      ngbTooltip="My Results">
      <a>
        <i class="sidebar-item-icon result"> </i>
        <span class="nav-label">My Results </span>
      </a>
    </li>
    <li routerLinkActive="active" [routerLink]="localData?.isPlanActive !=0 ? '/favorites' : '/plan/buy'"
      ngbTooltip="My Favourites">
      <a>
        <i class="sidebar-item-icon fav"></i>
        <span class="nav-label"> My Favourites </span>
      </a>
    </li>
    <li *ngIf="(localData?.isAdmin == 1) || (localData?.userType == 3 && localData?.isTeamAdmin == 1)"  routerLinkActive="active" [routerLink]="localData?.isPlanActive !=0 ? '/team' : '/plan/buy'"
      ngbTooltip="My Team">
      <a>
        <i class="sidebar-item-icon team"></i>
        <span class="nav-label">My Team </span>
      </a>
    </li>
    <li *ngIf="(localData?.isAdmin == 1)  || (localData?.userType == 3 && localData?.isTeamAdmin == 1)"  routerLinkActive="active" [routerLink]="localData?.isPlanActive !=0 ? '/license' : '/plan/buy'"
      ngbTooltip="Licence">
      <a>
        <i class="sidebar-item-icon lic"></i>
        <span class="nav-label"> Licence </span>
      </a>
    </li>
    <li *ngIf="(localData?.isAdmin == 1)  || (localData?.userType == 3 && localData?.isTeamAdmin == 1)"  routerLinkActive="active"
      [routerLink]="localData?.isPlanActive !=0 ? '/purchase-history' : '/plan/buy'"
      ngbTooltip="Purchase history">
      <a>
        <i class="sidebar-item-icon pur"></i>
        <span class="nav-label"> Purchase history </span>
      </a>
    </li>
    <li routerLinkActive="active"
      [routerLink]="localData?.isPlanActive !=0 ? '/default-setting' : '/plan/buy'"
      ngbTooltip="My Preferences">
      <a>
        <i class="sidebar-item-icon def"></i>
        <span class="nav-label"> My Preferences </span>
      </a>
    </li>
    <li *ngIf="localData?.isAdmin == 1" routerLinkActive="active"
      [routerLink]="localData?.isPlanActive !=0 ? '/admin-setting' : '/plan/buy'" ngbTooltip="Admin settings">
      <a>
        <i class="sidebar-item-icon admin_ic"></i>
        <span class="nav-label"> Admin settings </span>
      </a>
    </li>
    <li *ngIf="localData?.isAdmin == 1" routerLinkActive="active"
             ngbTooltip="Share your Feedback">
      <a href="mailto:support@taxcanary.com?subject=Feedback">
        <i class="sidebar-item-icon admin_ics"></i>
        <span class="nav-label"> Share your Feedback </span>
      </a>
    </li>
     
  </ul>
</nav>
<!-- END SIDEBAR-->