import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-faq',
  templateUrl: './mobile-faq.component.html',
  styleUrls: ['./mobile-faq.component.css']
})
export class MobileFaqComponent implements OnInit {
  collapsed:any = [];

  constructor() { }

  ngOnInit(): void {
  }

  expandToggle(item) {
    this.collapsed[item] = !this.collapsed[item];
    this.collapsed.filter((res,i)=> {
      if(i != item) {
        this.collapsed[i] = false;
      }
    })
  }
}
