import { Component, OnInit } from "@angular/core";
import { AdminService } from "../../services/admin.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"],
})
export class LoaderComponent implements OnInit {
  spinner;
  constructor(
    private admin: AdminService
  ) {}

  ngOnInit(): void {
    this.admin.loaderState.subscribe((data) => {
      this.spinner = true;
      if (data) {
        this.spinner = true;
      } else {
        this.spinner = false;
      }
    });
  }
}
