<app-header *ngIf="localData?.userData"></app-header>
<div class="main_header" *ngIf="!localData?.userData">
    <div class="left-header">
      <a >
        <img class="logo" src="assets/images/ic_logo.svg">
      </a>
     
    </div>
  
    <div class="right-header">

  </div>
</div>
<div class="faq-wrapper">
  <div class="container smallcontainer">
    <div class="table-cover">
      <div class="table-wrapper">
        <a *ngIf="localData?.userData" class="back_button" (click)="back()">
          <img src="assets/images/ic_back.svg">
          <span>Back</span>
        </a>
        <div class="title-bar">
          <h1>FAQ.</h1>
        </div>
        <div class="order-summery">
          <!-- <div class="searchBox">
            <input minlength="2"
                type="text" placeholder="Search...">
            <a class="closeSearch" [ngClass]="hideClose !='1' ? 'search':'' "></a>
        </div> -->
        <div class="accordion">
          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(1)" data-toggle="collapse" data-target="#1"
              aria-expanded="collapsed[1]" aria-controls="1">What is a tax insight?
            </h3>
            <i [class]="collapsed[1] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(1)" data-toggle="collapse" data-target="#1" aria-expanded="collapsed[1]"></i>
            <div id="1" [class]="collapsed[1] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                The tax insight that is generated gives you information related to direct tax on the sales you make to the customer in the country you have chosen.  Your tax insight includes one transaction, and doesn’t take into account any other activities that you might have in your customer’s country.
              </div>
            </div>
          </div>
          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(2)" data-toggle="collapse" data-target="#2"
              aria-expanded="collapsed[2]" aria-controls="2">I need to get a residency certificate, what’s this?
            </h3>
            <i [class]="collapsed[2] ? 'bar' : 'bar bar-after'" aria-hidden="true" (click)="expandToggle(2)"
              data-toggle="collapse" data-target="#2" aria-expanded="collapsed[2]" aria-controls="2"
              class="expand-icon pointer"></i>
            <div id="2" [class]="collapsed[2] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                A residency certificate (sometimes called a tax residency certificate) is a certificate either signed or issued by the taxation authority of your company’s (or your) home country.  It confirms that you are a tax resident in your home country. In the United Kingdom, you apply for a tax residency certificate from HMRC.  There may be special requirements depending on your customer country.
              </div>
            </div>
          </div>

          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(3)" data-toggle="collapse" data-target="#3"
              aria-expanded="collapsed[3]" aria-controls="3">What about indirect taxes?
            </h3>
            <i [class]="collapsed[3] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(3)" data-toggle="collapse" data-target="#3" aria-expanded="collapsed[3]"
              aria-controls="3"></i>
            <div id="3" [class]="collapsed[3] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                Tax Canary does not cover indirect taxes such as GST, VAT, customs, or excise duties.  You can usually exclude these taxes in your customer contract, so you should check that your contract does this.
              </div>
            </div>
          </div>
          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(4)" data-toggle="collapse" data-target="#4"
              aria-expanded="collapsed[4]" aria-controls="4">What is withholding tax?
            </h3>
            <i [class]="collapsed[4] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(4)" data-toggle="collapse" data-target="#4" aria-expanded="collapsed[4]"
              aria-controls="4"></i>
            <div id="4" [class]="collapsed[4] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                Withholding tax is a prepayment of income tax.  If you’ve ever been an employee, you’ll be familiar with the concept of your employer holding back some of your pay and sending that withheld tax to the tax authority.  That withheld tax is then offset against your personal income tax liability at the end of the tax year.  Withholding tax on payments from overseas operate in the same way and ensure that the tax authority of the country get their “share” of tax on your transaction.
              </div>
            </div>
          </div>


          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(5)" data-toggle="collapse" data-target="#5"
              aria-expanded="collapsed[5]" aria-controls="5">Are there any assumptions used by Tax Canary?
            </h3>
            <i [class]="collapsed[5] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(5)" data-toggle="collapse" data-target="#5" aria-expanded="collapsed[5]"
              aria-controls="5"></i>
            <div id="5" [class]="collapsed[5] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                Yes, Tax Canary assumes that if you pick the “company” option, that you are a company for taxation purposes.
              </div>
            </div>
          </div>


          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(6)" data-toggle="collapse" data-target="#6"
              aria-expanded="collapsed[6]" aria-controls="6">I’m looking for information about tax related to starting a business in a new country – can Tax Canary help me?
            </h3>
            <i [class]="collapsed[6] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(6)" data-toggle="collapse" data-target="#6" aria-expanded="collapsed[6]"
              aria-controls="6"></i>
            <div id="6" [class]="collapsed[6] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                Tax Canary is designed to provide tax insights based on the assumption that you are not setting up a business in your customer’s country.  You will need to get specific tax advice if you wish to open a branch or an office in a new country.  We’d recommend you approach a qualified tax advisor before doing so.
              </div>
            </div>
          </div>

          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(7)" data-toggle="collapse" data-target="#7"
              aria-expanded="collapsed[7]" aria-controls="7">My customer withheld tax at a different rate. Why?
            </h3>
            <i [class]="collapsed[7] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(7)" data-toggle="collapse" data-target="#7" aria-expanded="collapsed[7]"
              aria-controls="7"></i>
            <div id="7" [class]="collapsed[7] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                In most countries, the customer is legally responsible for withholding tax, and in many countries, subject to penalties if they make a mistake.  It is important to talk about tax and your customer’s withholding expectations early, so you know what they plan to do, and are able to discuss it with them.  Then you can change your price accordingly.
              </div>
            </div>
          </div>

          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(8)" data-toggle="collapse" data-target="#8"
              aria-expanded="collapsed[8]" aria-controls="8">How do I assign a licence? 
            </h3>
            <i [class]="collapsed[8] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(8)" data-toggle="collapse" data-target="#8" aria-expanded="collapsed[8]"
              aria-controls="8"></i>
            <div id="8" [class]="collapsed[8] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                To assign a licence you have purchased, go to My Account, select Licence, and assign it to another member of your team.  If your team member has no profile, go to My Team and Add New User to add in your team member and assign a licence straight away.
              </div>
            </div>
          </div>


          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(9)" data-toggle="collapse" data-target="#9"
              aria-expanded="collapsed[9]" aria-controls="9">How can I upgrade my subscription?
            </h3>
            <i [class]="collapsed[9] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(9)" data-toggle="collapse" data-target="#9" aria-expanded="collapsed[9]"
              aria-controls="9"></i>
            <div id="9" [class]="collapsed[9] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                You can upgrade to a yearly subscription by clicking on the Buy Licence button.  You should only do this when your month licence has ended, as the yearly subscription will commence as soon as you purchase it.
              </div>
            </div>
          </div>
          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(10)" data-toggle="collapse" data-target="#10"
              aria-expanded="collapsed[10]" aria-controls="10">I'm in a partnership, is Tax Canary for me?
            </h3>
            <i [class]="collapsed[10] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(10)" data-toggle="collapse" data-target="#10" aria-expanded="collapsed[10]"
              aria-controls="10"></i>
            <div id="10" [class]="collapsed[10] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                Unfortunately, Tax Canary only provides international tax insights to businesses that are in the form of an individual, or an incorporated company. You'll need to seek professional tax advice.
              </div>
            </div>
          </div>
          <div class="accordion-head active position-relative">
            <h3 class="title" (click)="expandToggle(11)" data-toggle="collapse" data-target="#11"
              aria-expanded="collapsed[11]" aria-controls="11">We have a not-for-profit / charitable organisation. Is Tax Canary for us?
            </h3>
            <i [class]="collapsed[11] ? 'bar' : 'bar bar-after'" aria-hidden="true" class="expand-icon pointer"
              (click)="expandToggle(11)" data-toggle="collapse" data-target="#11" aria-expanded="collapsed[11]"
              aria-controls="11"></i>
            <div id="11" [class]="collapsed[11] ? 'show' : 'collapse'" aria-labelledby="headingTwo"
              data-parent="#accordion">
              <div class="card-body" style="padding: 0 20px 20px 00px;font-size: 16px;">
                Unfortunately, Tax Canary only provides international tax insights to businesses that are in the form of an individual, or an incorporated company. You'll need to seek professional tax advice. as the rules for non-profits can be different.
              </div>
            </div>
          </div>




        </div>
        </div>
        <!-- end -->
      </div>
    </div>
  </div>
</div>