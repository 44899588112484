import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./services/auth.guard";
import { NotFoundComponent } from "./extrnal/not-found/not-found.component";
import { PrivacyPolicyComponent } from "./internal/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./internal/terms-conditions/terms-conditions.component";
import { FaqComponent } from "./internal/faq/faq.component";
import { MobileTermsConditionComponent } from "./extrnal/mobile-terms-condition/mobile-terms-condition.component";
import { MobilePrivacyPolicyComponent } from "./extrnal/mobile-privacy-policy/mobile-privacy-policy.component";
import { MobileFaqComponent } from "./extrnal/mobile-faq/mobile-faq.component";
import { SharedResultComponent } from "./extrnal/shared-result/shared-result.component";
import { BuyLicenseMobileComponent } from "./extrnal/buy-license-mobile/buy-license-mobile.component";
import { InternalHeaderComponent } from "./common/internal-header/internal-header.component";

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./extrnal/auth/auth.module').then(m => m.AuthModule) },
  {
    path: "", canActivate: [AuthGuard], component: InternalHeaderComponent,  loadChildren: () =>
      import("./internal/internal.module").then((m) => m.InternalModule),
  },
  { path: "terms-conditions", component: MobileTermsConditionComponent },
  { path: "privacy-policy", component: MobilePrivacyPolicyComponent },
  { path: "faq", component: MobileFaqComponent },
  { path: "web-privacy-policy", component: PrivacyPolicyComponent },
  { path: "web-faq", component: FaqComponent },
  { path: "web-terms-conditions", component: TermsConditionsComponent },
  { path: "share-result/:id/:userId", component: SharedResultComponent },
  { path: "buy-license-mobile", component: BuyLicenseMobileComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
