import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Inject, PLATFORM_ID } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class DataSharingService {
  public isLoginChanged: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isProfileChanged: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isPermissionChanged: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public userAvatar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public searchParam: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public searchEmpty: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // public notificationHeader: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public notificationHeader: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);

  public notificationPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public notificationData: BehaviorSubject<boolean> = new BehaviorSubject<any>(null);
  public newMessageRecive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public clearNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  private storage: any;
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    // if (isPlatformBrowser(this.platformId)) {
      this.storage = localStorage;
    // }
  }
  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }

  setUserAvatar(value) {
    this.userAvatar.next(value);
  }

  passNotification(value) {
    // console.log('pass', value)
    this.notificationHeader.next(value);
  }


  searchFaq(query: string) {
    this.searchParam.next(query);
  }
}
