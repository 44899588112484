import { Component, OnInit } from "@angular/core";
import { environment } from '../../../environments/environment'
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  env = environment
  constructor() { }

  ngOnInit(): void {
  }

}
