import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AdminService } from "./admin.service";
import { DataSharingService } from "./data.share.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private localStorage: DataSharingService) {}
  canActivate() {
    let token:any = this.localStorage.getItem('userData') ? JSON.parse(this.localStorage.getItem('userData') || '') : '';
    if (token && token.userData.accessToken) {
      return true;
    }

    this.router.navigate(["/login"]);
    return false;
  }
}
