import { HttpParams } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-shared-result',
  templateUrl: './shared-result.component.html',
  styleUrls: ['./shared-result.component.css']
})
export class SharedResultComponent implements OnInit {
  id;
  imageUrl = environment.imgUrl;
  userId;
  resultDetails;
  userDetail;
  collapsed: any = [false,true];
  modalRef;
  filteredOptions;
  searchTerm;
  curuncy='£';
  grossAmount;
  calculatedData;
  showError: boolean = false;
  modalRef1;
  infoTxt;
  headTxt;
  allData;
  constructor(
    private modalService: BsModalService,
    private api: AdminService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params) {
        this.id = params['id'];
        this.userId = params['userId'];
        this.getResult()
      }
    })
    this.getCountryList();
  }

  expandToggle(item) {
    this.collapsed[item] = !this.collapsed[item];
    this.collapsed.filter((res, i) => {
      if (i != item) {
        this.collapsed[i] = false;
      }
    })
  }

  getResult() {
    let params = new HttpParams();
    params = params.append('id', this.id);
    params = params.append('userId', this.userId);
    this.api.getData(apiUrl._getSharedResult, params).subscribe(res => {
      if (res) {
        this.resultDetails = res.data.Results;
        this.userDetail = res.data.userDetail;
        this.allData = res.data;
      }
    })
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      animated: true,
      class: 'modal-lg modal-dialog-centered whatsPopup w-5',
    });
  }

  closeModal() {
    this.modalRef.hide();
  }

  sendMail() {
    window.open(`mailto:${this.userDetail?.contactEmail}`)
  }

  openPopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-dialog-centered w-5 modal-confirm calculate-popup',
    });
  }

  clear() {
    this.calculatedData = '';
    this.curuncy = '';
    this.grossAmount = '';
    this.modalRef.hide();
  }

  /*number only */
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selectCurrency(data) {
    this.curuncy = data?.currencySymbol;
    this.searchTerm = '';
    this.getCountryList();
  }

  getCountryList() {
    let params = new HttpParams();
    if (this.searchTerm) {
      params = params.append('search', this.searchTerm);
    }
    this.api.getData(apiUrl._countryList, params).subscribe((res) => {
      this.filteredOptions = res.data.rows;
    })
  }

  calculateData() {
    if (this.curuncy && this.grossAmount) {
      const data = {
        resultId: this.id,
        symbol: this.curuncy,
        grossAmount: this.grossAmount,
        userId: this.userId
      }
      this.api.putData(apiUrl._sharedCalculation, data).subscribe(res => {
        if (res) {
          this.calculatedData = res.data;
        }
      })
    } else {
      this.showError = true;
      setTimeout(() => { this.showError = false; }, 3000);
    }
  }

  infoPopup(template: TemplateRef<any>,text,heading) {
    this.infoTxt = `"`+heading+`" `+text;
    this.headTxt = heading;
    this.modalRef1 = this.modalService.show(template, {
      animated: true,
      class: 'modal-dialog-centered quitPopup w-5',
    });
  }

  utcToLocal(utcDate) {
    if (utcDate) {
      let date = moment.utc(utcDate).local().format('DD MMM, yyyy');
      return date;
    } else {
      return utcDate
    }
  }

  next(value){
    // console.log('value', value)
    let data ;
    data = value.replace(new RegExp('\n', 'g'), "<br />")
    return data
  }

}
