import { Location } from '@angular/common';
import { Component, OnInit } from "@angular/core";
import { DataSharingService } from 'src/app/services/data.share.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
  localData;
  constructor(
    private location: Location,
    private dataSharingService: DataSharingService
  ) {
    this.localData = this.dataSharingService.getItem('userData') ? JSON.parse(this.dataSharingService.getItem('userData') || '') : '';
  }

  ngOnInit(): void {
    // console.log('paget')
  }

  back() {
    this.location.back();
  }

}
