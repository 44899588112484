import { Component, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { DataSharingService } from "src/app/services/data.share.service";

import { Router } from '@angular/router';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  collapsed:any = [];
  hideClose = '0';
  localData;

  constructor(
    private router: Router,
    private location: Location,
    private dataSharingService: DataSharingService,
  ) { }

  ngOnInit(): void {
    this.localData = this.dataSharingService.getItem('userData') ? JSON.parse(this.dataSharingService.getItem('userData') || '') : '';
  }

  expandToggle(item) {
    this.collapsed[item] = !this.collapsed[item];
    this.collapsed.filter((res,i)=> {
      if(i != item) {
        this.collapsed[i] = false;
      }
    })
  }

  back() {
    // this.location.back();
    this.router.navigate(["/tax-guide"])
  }
}