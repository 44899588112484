import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from "./header/header.component";
import { InternalHeaderComponent } from "./internal-header/internal-header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NgxPaginationModule } from "ngx-pagination";
import { SafeHtmlPipe } from "./../pipe/safe-html";

@NgModule({
  declarations: [
    SafeHtmlPipe,
    HeaderComponent,
    SidebarComponent,
    InternalHeaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxPaginationModule
  ],
  exports : [
    SafeHtmlPipe,
    HeaderComponent,
    InternalHeaderComponent,
    SidebarComponent,
    NgxPaginationModule
  ]
})
export class SharedModule { }
