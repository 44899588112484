import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
/////service===
import { InterceptorService } from "./services/interceptor.service";
import { environment } from "src/environments/environment";
import { AsyncPipe } from "@angular/common";
import { DataSharingService } from "./services/data.share.service";
import { ServiceWorkerModule } from "@angular/service-worker";

//npm package===

import { ToastrModule } from "ngx-toastr";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

/////Component
import { LoaderComponent } from "./common/loader/loader.component";
import { InternalComponent } from "./internal/internal.component";
import { HomePageComponent } from './extrnal/home-page/home-page.component';
import { SharedResultComponent } from "./extrnal/shared-result/shared-result.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from "ngx-pagination";
import { DeeplinkService } from "./services/deeplink.service";
import { BuyLicenseMobileComponent } from "./extrnal/buy-license-mobile/buy-license-mobile.component";
import{ MessagingService} from './services/messaging.service';

import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule, AngularFireList, AngularFireDatabase  } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { SharedModule } from "./common/shared.module";

@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent,
        InternalComponent,
        SharedResultComponent,
        HomePageComponent,
        BuyLicenseMobileComponent,
    ],
    providers: [
        AsyncPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
        },
        DataSharingService,
        MessagingService,
        DeeplinkService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        RouterModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        HttpClientModule,
        ServiceWorkerModule,
        NgxPaginationModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 2500,
            positionClass: "toast-top-right",
            preventDuplicates: true,
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireDatabaseModule,
        SharedModule
    ]
})
export class AppModule { }
