import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { DataSharingService } from "../../services/data.share.service";
import { environment } from "src/environments/environment";
import { BsModalService } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as CONSTANT from "../../services/constants";
import { ToastrService } from "ngx-toastr";
import { HttpParams } from "@angular/common/http";
import { MessagingService } from "src/app/services/messaging.service";
import { NavigationEnd  } from '@angular/router';
import { take } from 'rxjs/operators';
import {filter} from 'rxjs/operators';
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  toggleSidebar = false;
  localData;
  imageUrl = environment.imgUrl;
  public form: FormGroup;
  modalRef;
  passwordType: string = 'password';
  showError: boolean = false;
  toggle: boolean = false;
  href;
  newNotificaton: boolean = false;
  loader: boolean = false;

  notificationList: any = [];

  notification: any = [];
  indexisReaded:any = null;
  indexisRead:any = null;
  indexisAnnouncement = null;
  localStorage:any;
  notificationCheck =false;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private admin: AdminService,
    private dataSharingService: DataSharingService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private message : MessagingService
  ) {
    this.form = this.formBuilder.group({
      password: ["", Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(CONSTANT.PASSWORD_REGEX)])],
    })
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if(event.url!="/login" && (event.url=="/tax-guide" || event.url=="/my-result" || event.url=="/favorites" || event.url=="/team" || event.url=="/license" || event.url=="/purchase-history" || event.url=="/admin-setting" || event.url=="/change-password" || event.url=="/my-profile")){
          this.getNotifications();
       }
      }
    })
    this.dataSharingService.clearNotification.subscribe(value => {
      if (value == true) {
      this.newNotificaton = false;
      this.getNotifications();
      }
    });

    
    document.addEventListener(
      "visibilitychange"
      , () => {
        
        if (document.hidden) {
          // console.log('dataaaaaaaaaa 1')
          // console.log("document is hidden");
        } else {
          let data = JSON.parse(localStorage.getItem('userData') || '')
          if(data.userData.isPlanActive == 2)
          {
         
            // this.getNotifications();
            data ='';
          }

        }
      }
    );

  }



  ngOnInit(): void {
    
    this.localStorage =  JSON.parse(localStorage.getItem('userData') || '');
    console.log('notification', this.localStorage.userData.notificationEnabled);
    this.dataSharingService.notificationHeader.subscribe((item) => { 
      if (item == true) {
        this.notificationCheck = true;
        this.newNotificaton = true;
        // console.log('hit 2', item)
        // this.getNotifications();
      }else{
        this.notificationCheck = false;
        this.newNotificaton = false;
      }
    })

    this.getNotifications();

    // if(!this.notificationCheck)
    // 
 
    this.href = this.router.url;
    this.dataSharingService.userAvatar.subscribe((res) => {
      if (res) {
        this.localData = this.dataSharingService.getItem('userProfile') ? JSON.parse(this.dataSharingService.getItem('userProfile') || '') : '';
        console.log('data 2', this.localData)
      }
    })
    this.localData = this.dataSharingService.getItem('userProfile') ? JSON.parse(this.dataSharingService.getItem('userProfile') || '') : '';
    // if (!this.localData) {
    //   this.localData = this.dataSharingService.getItem('userData') ? JSON.parse(this.dataSharingService.getItem('userData') || '') : '';
    // }
    this.getUserData(this.localStorage?.userData?.id)

    

  }
  getUserData(id) {
    const data = {
      id: id,
    }
    this.admin.getData(apiUrl._userById,data).subscribe((res) => {
      
      this.localData= res.data
       console.log('data', this.localData)
      
    })
  }

  getNotifications(){
    // console.log('check 3')
    this.admin.loader(false);
    let params = new HttpParams();
   
    this.admin.getNoloader(apiUrl._getNotification, params)
      .subscribe(res => {
        this.notification = res.data.unRead
        if(this.notification.length == 0){
          // console.log('hit 3', this.newNotificaton)
          this.newNotificaton = false;
        }
        this.notification.filter((item: any) => {
          if(item.isRead == 0){
            this.newNotificaton = true
          }
        });
        // console.log('hit 4', this.newNotificaton)
      })
  
  }

  

  public togglePassword() {
    this.passwordType == 'password' ? this.passwordType = 'text' : this.passwordType = 'password';
  }

  logout() {
    this.admin.logOut();
  }

  toggleNavbar(event: any) {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("sidebar-mini");
    if ((this.toggleSidebar = !this.toggleSidebar)) {
    } else {
      body.classList.remove("sidebar-mini");
    }
  }

  openPopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-dialog-centered w-5 modal-confirm',
    });
  }

  close() {
    this.modalRef.hide();
  }

  delete() {
    this.showError = true;
    if (this.form.valid) {
      this.admin.deleteData(apiUrl._user, this.form.value).subscribe((res) => {
        if (res) {
          this.modalRef.hide();
          this.toastrService.success("Account deleted successfully");
          this.router.navigate(["/login"])
          this.dataSharingService.clear();
        }
      })
    }
  }

  notificationOnoff() {
    const data ={
      'notificationEnabled': this.localStorage.userData.notificationEnabled == 1 ? 0 : 1,
     }

      this.admin.putData(apiUrl._putNoti, data).subscribe((res) => {
        // localStorage.setItem('userData', JSON.stringify({ notificationEnabled: this.localStorage.userData.notificationEnabled == 1 ? 0 : 1 }));


        let localDatas = JSON.parse(localStorage.getItem('userData') || '');
        localDatas[('userData')].notificationEnabled =  res.data.notificationEnabled;
        // console.log('nn',  localDatas[('userData')].notificationEnabled)
        localStorage.setItem('userData', JSON.stringify(localDatas));

        
        if (res) {
          if(this.localStorage.userData.notificationEnabled  == 0){
            this.toastrService.success("Notification disable successfully");
          }else{
            this.toastrService.success("Notification unable successfully");
          }
          
 
        }
      })
    
  }

  checkBack() {
    const data = {
      id: this.localStorage?.userData?.id,
    }
    this.admin.getData(apiUrl._userById,data).subscribe((res) => {
      
      this.localData= res.data
      console.log('this.localData', this.localData)
      if(this.localData?.isPlanActive == 2){
        this.router.navigate(['/tax-guide']) 
      }else{
         this.router.navigate(["/plan/buy"])
      }
      
    })

 

    // this.localData.userData.isPlanActive == 1 ?
    //   this.router.navigate(['/tax-guide']) : this.router.navigate(["/plan/buy"])

  }

  sideToggle() {
    let body = document.getElementsByTagName('body')[0];
    if (!this.toggle) {
      body.classList.add("sidebar-mobile");
      this.toggle = true;
    } else {
      body.classList.remove("sidebar-mobile");
      this.toggle = false;
    }
  }

  shouldShow() {
    if ((this.localData?.userData?.isPlanActive == 2 || this.localData?.isPlanActive == 2) && (!this.href.includes('/my-profile') && !this.href.includes('/change-password') && !this.href.includes('/terms-conditions') && !this.href.includes('/privacy-policy') && !this.href.includes('/faq') && !this.href.includes('/insight')) ) {
      return true;
    } else {
      return false;
    }
  }
}
