<div class="privacy-policy">
    <div class="smallcontainer">
        <div class="termsWrap">
            <h1> PRIVACY POLICY  </h1>
            <p class="update-date"> Effective: April 4, 2023</p>

            <p>By using the Service, you understand and agree to the collection and use of information in accordance with this Privacy Policy. Our Privacy Policy applies to all visitors, users, and others who access the Service ("Users").  </p>

            <h5>What kinds of information do we collect?  </h5>
            
            <p>Information you provide </p>

            <p>We collect the following information you provide us with directly: </p>

            <ol>
                <li> Your username, password and e-mail address when you register for a Tax Canary account; </li>
                <li> Profile information that you provide for your user profile; </li>
                <li> Communications between you and Tax Canary (e.g., we may send you Service-related emails); </li>
                <li> Company name, position, </li>
            </ol>


            <h5>Log file information </h5>
            <p> We collect information that your browser sends whenever you visit our Service. This log file information may include information such as your computer's Internet Protocol address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics. </p>

            <h5>Location information </h5>
            <p> We may use and store information about your location if you give us permission to do so. We use this information to provide features of our Service, to improve and customize our Service. You can enable or disable location services when you use our Service at any time, through your mobile device settings. </p>

            <h5> Analytics services (non-personally identifiable information only) </h5>

            <p> We use third-party analytics tools to help us measure traffic and usage trends for the Service. These tools collect information sent by your device or our Service, including the web pages you visit, add-ons, and other information that assists us in improving the Service. The tools use ‘cookies’, which are text files placed on your device, to collect your log information and behavior information in an anonymous form. Cookies cannot damage your device or the files on your device. We collect and use this analytics information with analytics information from other Users so that it cannot reasonably be used to identify any particular individual User. With respect to Google Analytics, although Google Analytics plants a permanent cookie on your web browser to identify you, the cookie cannot be used by anyone but Google. Google’s ability to use and share information collected by Google Analytics about your visits is restricted by the Google Analytics Terms of Use and the Google Privacy Policy. You can prevent Google Analytics from recognizing you on return visits by disabling cookies. </p>


            <h5>How do we use this information? </h5>
            <p> We use all the information we have to help us provide and support our Services. Here is how: </p>
            <ol>
                <li> remember information so you will not have to re-enter it during your visit or the next time you visit the Service; </li>
                <li> provide, improve, test, and monitor the effectiveness of our Service; </li>
                <li> To help you efficiently access your information after you sign in; </li>
                <li> monitor metrics such as total number of visitors, traffic, and demographic patterns; </li>
                <li> diagnose or fix technology problems; </li>
                <li> develop and test new products and features; and </li>
                <li> automatically update the Tax Canary application on your device. </li>
            </ol>

            <h5> How is this information shared? </h5>
            <p> We will not rent or sell your information to third parties outside Tax Canary. </p>

            <h5> Change of control </h5>
            <p> If we sell or otherwise transfer part or the whole of Tax Canary or our assets to another organization (e.g., in the course of a transaction like a merger, acquisition, bankruptcy, dissolution, liquidation), your information collected through the Service may be among the items sold or transferred. The buyer or transferee will have to honor the commitments we have made in this Privacy Policy.  </p>


            <h5> Legal requests and preventing harm </h5>
            <p> We may access, preserve, and share your information in response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so. We may also access, preserve, and share information when we have a good faith belief it is necessary to: detect, prevent and address fraud and other illegal activity; to protect ourselves, you and others, including as part of investigations; and to prevent death or imminent bodily harm. Information we receive about you may be accessed, processed and retained for an extended period of time when it is the subject of a legal request or obligation, governmental investigation, or investigations concerning possible violations of our terms or policies, or otherwise to prevent harm. </p>


            <h5> Safety and security </h5>
            <p> Tax Canary has taken appropriate technical and organizational measures by using the latest technologies to protect your information against misuse, loss, unauthorized access, unwanted disclosure, unauthorized modification or any other form of unlawful processing. We use safeguards to help keep the information collected through the Service secure and take steps (such as requesting a unique password) to verify your identity before granting you access to your account. However, Tax Canary cannot ensure the security of any information you transmit to Tax Canary or guarantee that information on the Service may not be accessed, disclosed, altered, or destroyed. We request you to do your part to help us. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to emails between you and Tax Canary, at all times. We are not responsible for the functionality, privacy, or security measures of any other organization. </p>

            <h5>International transfer  </h5>
            <p>Your information may be transferred to, and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. If you are located outside the Netherlands and choose to provide information to us, please note that we transfer the information to The Netherlands. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.   </p>


            <h5>Retention period </h5>
            <p>In accordance with the law, Tax Canary does not retain data any longer than is required for attaining the purposes for which they were collected, unless Tax Canary is obliged to do so based on a legal provision.  </p>



             <h5>Inspection and correction  </h5>
            <p>If you wish to know which of your data Tax Canary has recorded or if you wish to amend or remove data that you cannot amend via your account, please contact Tax Canary.   </p>



            <h5>Third-party applications, websites and services  </h5>
            <p> We are not responsible for the practices employed by any applications, websites or services linked to or from our Service, including the information or content contained within them. Please remember that when you use a link to go from our Service to another application, website or service, our Privacy Policy does not apply to those third-party applications, websites or services. Your browsing and interaction on any third-party application, website or service, including those that have a link on our Services, are subject to that third party's own rules and policies.</p>



            <h5>Children's privacy  </h5>
            <p>Our Service does not address anyone under the age of 13 ("Children"). This age is depending on the age established in each EU Member State. We do not knowingly collect personally identifiable information from children under 13. The age threshold for obtaining parental consent varies between 13 and 16 years. If you are a parent or guardian and you are aware that your Child has provided us with personal information, please contact us (see “How to contact us”) . If we become aware that we have collected personal information from a child under the age of 13 without verification of parental consent, we take steps to remove that information from our servers. </p>



            <h5>Changes to this privacy policy  </h5>
            <p>Tax Canary may modify or update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.  </p>

            <h5>How to contact us  </h5>
            <p> If you have any questions about this Privacy Policy, please send an email to <a href="mailto:support@taxcanary.com"> support@taxcanary.com </a>. </p>

        </div>
    </div>
</div>
