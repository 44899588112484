<div class="terms-conditions">
    <div class="smallcontainer">
        <div class="termsWrap">
            <h1>TERMS AND CONDITIONS</h1>
            <!-- <p class="update-date"> Effective: April 4, 2023</p> -->
            <p>
                These are the Terms and Conditions ("Terms") of Tax Canary B.V. ("Tax Canary," "we," "us" or "our"), a
                company with limited liability having its address at Wilhelmina van Pruisenweg 35, 2595 AN, Den Haag.
                Tax Canary is
                registered with the Chamber of Commerce under number 88586537. By accessing or using our (mobile)
                application (“App”) called “Tax Canary”, websites or any other Tax Canary service (together, the
                "Service") made available by
                Tax Canary, however accessed, you agree to be bound by these Terms. The Service is owned or controlled
                by Tax Canary. These Terms affect your legal rights and obligations. If you do not agree to be bound by
                all of these
                Terms, do not access or use the Service.
            </p>

            <h5>Article 1. Registration and account security</h5>

            <ol class="number">
                <li>You must be at least 13 years old to use the Service. If you are under the age of 16, you need
                    permission from a parent or guardian to use the Service.</li>

                <li>
                    You are responsible for any activity that occurs through your account and you agree you will not
                    sell, transfer, license or assign your account, username, or any account rights. With the exception
                    of people or businesses
                    that are expressly authorized to create accounts on behalf of their employees or clients, Tax Canary
                    prohibits the creation of and you agree that you will not create an account for anyone other than
                    yourself.
                </li>

                <li>
                    You represent that all information you provide or provided to Tax Canary upon registration and at
                    all other times will be true, accurate, current and complete and you agree to update your
                    information as necessary to
                    maintain its truth and accuracy.
                </li>

                <li>You agree that you will not solicit, collect or use the login credentials of other Tax Canary users.
                </li>

                <li>You must not attempt to restrict another user from using or enjoying the Service and you must not
                    encourage or facilitate violations of these Terms or any other Tax Canary terms.</li>

                <li>You are responsible for keeping your password secret and secure.</li>

                <li>You must not create accounts with the Service through unauthorized means, including but not limited
                    to, by using an automated device, script, bot, spider, crawler or scraper.</li>
            </ol>

            <h5>Article 2. Safety</h5>

            <ol class="number">
                <li>
                    You may not use the Service for any illegal or unauthorized purpose. You agree to comply with all
                    laws, rules and regulations (for example state, local and provincial) applicable to your use of the
                    Service, including but
                    not limited to, copyright laws.
                </li>

                <li>
                    You must not interfere or disrupt the Service or servers or networks connected to the Service,
                    including by transmitting any worms, viruses, spyware, malware or any other code of a destructive or
                    disruptive nature. You
                    may not inject content or code or otherwise alter or interfere with the way any Tax Canary page is
                    rendered or displayed in a user's browser or device
                </li>

                <li>You must not change, modify, adapt or alter the Service or change, modify or alter another website
                    so as to falsely imply that it is associated with the Service or Tax Canary.</li>
            </ol>

            <h5>Article 3. General Conditions</h5>
            <ol class="number">
                <li>
                    We reserve the right to modify or terminate the Service or your access to the Service for any
                    reason, without notice, at any time, and without liability to you. If we terminate your access to
                    the Service or if you
                    deactivate your account your data will no longer be accessible through your account..
                </li>

                <li>We reserve the right to force forfeiture of any username for any reason.</li>

                <li>Upon termination, all rights granted to you in these Terms will immediately cease.</li>

                <li>We reserve the right to refuse access to the Service to anyone for any reason at any time.</li>

                <li>You agree that you are responsible for all data charges you incur through use of the Service</li>

                <li>
                    We prohibit crawling, scraping, caching or otherwise accessing any content on the Service via
                    automated means (except as may be the result of standard search engine protocols or technologies
                    used by a search engine with
                    the express consent of Tax Canary).
                </li>
            </ol>

            <h5>Article 4. Intellectual Property Rights.</h5>
            <ol class="number">
                <li>
                    Tax Canary hereby grants to you a non-transferable license to use the Service. You may not
                    distribute or make the Service available over a network where it could be used by multiple devices
                    at the same time. You may not
                    rent, lease, lend, sell, redistribute or sublicense the Service. You may not copy (except as
                    expressly permitted by this license), decompile, reverse engineer, disassemble, attempt to derive
                    the source code of, modify,
                    or create derivative works of the Service, any updates, or any part thereof (except as and only to
                    the extent any foregoing restriction is prohibited by applicable law or to the extent as may be
                    permitted by the
                    licensing terms governing use of any open sourced components included with the Service). Any attempt
                    to do so is a violation of the rights of the Tax Canary. If you breach this restriction, you may be
                    subject to
                    prosecution and damages. The Terms will govern any upgrades provided by Tax Canary that replace
                    and/or supplement the original Service, unless such upgrade is accompanied by updated terms in which
                    case these terms will
                    govern.
                </li>

                <li>
                    The Service contains content owned or licensed by Tax Canary ("Tax Canary Content"). Tax Canary
                    Content is protected by , trademark, patent, trade secret and other laws, and, as between
                    you and Tax Canary, Tax
                    Canary owns and retains all rights in the Tax Canary Content and the Service. You will not remove,
                    alter or conceal any copyright, trademark or other proprietary rights notices incorporated in or
                    accompanying the Tax
                    Canary Content and you will not reproduce, modify, adapt, prepare derivative works based on,
                    perform, display, publish, distribute, transmit, broadcast, sell, license or otherwise exploit the
                    Tax Canary Content.
                </li>

                <li>
                    The Tax Canary name and logo are trademarks of Tax Canary, and may not be copied, imitated or used,
                    in whole or in part, without the prior written permission of Tax Canary. In addition, all page
                    headers, custom graphics,
                    button icons and scripts are proprietary rights of Tax Canary, and may not be copied, imitated or
                    used, in whole or in part, without prior written permission from Tax Canary.
                </li>
            </ol>

            <h5>Article 5. Third Parties</h5>
            <p>
                There may be links from the Service, or from communications you receive from the Service, to third-party
                (mobile) applications, websites or features. The Service may also include third-party content that we do
                not control,
                maintain or endorse. Tax Canary does not control any of these third-party web services or any of their
                content. You expressly acknowledge and agree that Tax Canary is in no way responsible or liable for any
                such third-party
                services or features. We strongly advise you to read the terms and conditions and privacy policies of
                any third-party web sites or services that you visit.
            </p>

            <h5>Article 6. No Warranty</h5>
            <ol class="number">
                <li>
                    The Service, including, without limitation, Tax Canary Content, is provided on an "as is", "as
                    available" and "with all faults" basis. To the fullest extent permissible by law, neither Tax Canary
                    nor any of its
                    directors, employees, managers, officers, partners, affiliates or agents (collectively, the "Tax
                    Canary Parties") make any representations or warranties or endorsements of any kind whatsoever,
                    express or implied, as to:
                    (a) the Service; (b) the Tax Canary Content; (c) user content; or (d) security associated with the
                    transmission of information to Tax Canary or via the Service. In addition, the Tax Canary Parties
                    hereby disclaim all
                    warranties, express or implied, including, but not limited to, the warranties of merchantability,
                    fitness for a particular purpose, non-infringement, title, custom, trade, quiet enjoyment, system
                    integration and freedom
                    from computer virus.
                </li>

                <li>
                    The Tax Canary Parties do not represent or warrant that the Service will be error-free or
                    uninterrupted; that defects will be corrected; or that the Service or the server that makes the
                    Service available is free from any
                    harmful components, including, without limitation, viruses. The Tax Canary Parties do not make any
                    representations or warranties that the information (including any instructions) on the Service is
                    accurate, complete, or
                    useful.
                </li>

                <li>
                    Although it is the intention of Tax Canary for the Service to be available as much as possible,
                    there will be occasions when the Service may be interrupted, including, without limitation, for
                    scheduled maintenance or
                    upgrades, for emergency repairs, or due to failure of telecommunications links and/or equipment.
                </li>

                <li>
                    You acknowledge that your use of the Service is at your sole risk. The Tax Canary Parties do not
                    warrant that your use of the Service is lawful in any particular jurisdiction, and the Tax Canary
                    Parties specifically
                    disclaim such warranties. Some jurisdictions limit or do not allow the disclaimer of implied or
                    other warranties so the above disclaimer may not apply to you to the extent such jurisdiction's law
                    is applicable to you and
                    these Terms.
                </li>

                <li>By accessing or using the Service you represent and warrant that your activities are lawful in every
                    jurisdiction where you access or use the Service.</li>
            </ol>

            <h5>Article 7. Subscription and Payment</h5>

            <ol class="number">
                <li>There are no charges for using the the Service during a free trial period. After the free trial
                    period the use of the Service is subject to a subscription fee.</li>

                <li>The subscription fee charged by Tax Canary applies per period and is charged in advance</li>

                <li>Payment is made by means of a link between our website and payment provider Stripe or via bank
                    transfer</li>
                <li>
                    You owe us the subscription fee until the moment the agreement between you and Tax Canary ends.
                    Unless otherwise agreed, you enter into a periodic agreement with us regarding the use of the
                    Service. You can terminate
                    this agreement at any time, subject to a notice period of 1 month. During the month's notice period,
                    you continue to owe the agreed upon subscription fee
                </li>

                <li>After the agreement between you and Tax Canary has been terminated, you no longer have access to
                    your account and you can no longer use the Service and we are no longer obliged store your account
                    details.</li>

                <li>We have the right to change our subscription fees at any given time. If you do not wish to accept
                    the changed subscription fee, you have the right to cancel the subscription by the time the changed
                    fee takes effect.</li>
            </ol>

            <h5>Article 8. Limitation of Liability</h5>

            <ol class="number">
                <li>
                    To the extent not prohibited by law, in no event shall the Tax Canary Parties be liable to you for
                    any loss or damages of any kind (including, without limitation, for any direct, indirect, economic,
                    exemplary, special,
                    punitive, incidental or consequential losses or damages) that are directly or indirectly related to:

                    <ol>
                        <li>the Service;</li>
                        <li>the Tax Canary Content;</li>
                        <li>user content;</li>
                        <li>your use of, inability to use, or the performance of the Service;</li>
                        <li>any action taken in connection with an investigation by the Tax Canary Parties or law
                            enforcement authorities regarding your or any other party's use of the Service;</li>
                        <li>any action taken in connection with copyright or other intellectual property owners;</li>
                        <li>any errors or omissions in the service's operation; or</li>
                        <li>
                            any damage to any user's computer, mobile device, or other equipment or technology
                            including, without limitation, damage from any security breach or from any virus, bugs,
                            tampering, fraud, error, omission,
                            interruption, defect, delay in operation or transmission, computer line or network failure
                            or any other technical or other malfunction, including, without limitation, damages for lost
                            profits, loss of goodwill,
                            loss of data, work stoppage, accuracy of results, or computer failure or malfunction, even
                            if foreseeable or even if the Tax Canary Parties have been advised of or should have known
                            of the possibility of such
                            damages, whether in an action of contract, negligence, strict liability or tort (including,
                            without limitation, whether caused in whole or in part by negligence, acts of god,
                            telecommunications failure, or theft
                            or destruction of the service).
                        </li>
                    </ol>
                </li>

                <li>
                    Tax Canary gathers information through tax databases, tax legislation (including rulings, circulars
                    and orders) and via third party websites (collectively "External Sources") and makes this
                    information available through
                    its Service. However, Tax Canary is not liable for incorrect or incomplete information originating
                    from these External Sources.
                </li>

                <li>
                    Any information provided by Tax Canary via the Service, including the Tax Canary App, cannot be
                    construed as tax advice. This includes any calculations made through our Service. Information
                    provided by Tax Canary is for
                    information purposes only and only meant to give an insight into what you may possibly expect in
                    your described situation. You are solely responsible for any harmful consequences of implementing,
                    following or making
                    decisions based on the information provided or made available by Tax Canary through its Service
                </li>

                <li>
                    In no event will the Tax Canary Parties be liable to you or anyone else for loss, damage or injury,
                    including, without limitation, death or personal injury. Some jurisdictions do not allow the
                    limitation of liability for
                    personal injury, or of incidental or consequential damages, so this limitation may not apply to you.
                    In no event will the Tax Canary Parties total liability to you for all damages, losses or causes or
                    action exceed the
                    amount of € 600. The foregoing limitations will apply even if the above stated remedy fails of its
                    essential purpose.
                </li>

                <li>
                    You agree that in the event you incur any damages, losses or injuries that arise out of acts of Tax
                    Canary or omissions, the damages, if any, caused to you are not irreparable or sufficient to entitle
                    you to an
                    injunction preventing any exploitation of any website, service, property, product or other content
                    owned or controlled by the Tax Canary Parties, and you will have no rights to enjoin or restrain the
                    development,
                    production, distribution, advertising, exhibition or exploitation of any website, property, product,
                    service, or other content owned or controlled by the Tax Canary Parties.
                </li>

                <li>
                    Tax Canary is not responsible for the actions, content, information, or data of third parties, and
                    you release us, our directors, officers, employees, and agents from any claims and damages, known
                    and unknown, arising
                    out of or in any way connected with any claim you have against any such third parties.
                </li>

                <li>You agree that any claim you may have arising out of or related to your relationship with Tax Canary
                    must be filed within one year after such claim arose; otherwise, your claim is permanently barred.
                </li>

                <li>The limitations of liability for damage contained in these Terms do not apply if the damage is due
                    to intent or gross negligence on the part of Tax Canary Parties.</li>
            </ol>

            <h5>Article 9. Indemnification</h5>

            <ol class="number">
                <li>
                    You agree to defend (at the request of Tax Canary), indemnify and hold the Tax Canary Parties
                    harmless from and against any claims, liabilities, damages, losses, and expenses, including without
                    limitation, reasonable
                    attorney's fees and costs, arising out of or in any way connected with any of the following
                    (including as a result of your direct activities on the Service or those conducted on your behalf):

                    <ol>
                        <li>your access to or use of the Service;</li>
                        <li>your breach or alleged breach of these Terms;</li>
                        <li>your violation of any third-party right, including without limitation, any intellectual
                            property right, publicity, confidentiality, property or privacy right;</li>
                        <li>
                            your violation of any laws, rules, regulations, codes, statutes, ordinances or orders of any
                            governmental and quasi-governmental authorities, including, without limitation, all
                            regulatory, administrative and
                            legislative authorities; or
                        </li>
                        <li>
                            any misrepresentation made by you. You will cooperate as fully required by Tax Canary in the
                            defence of any claim. Tax Canary reserves the right to assume the exclusive defence and
                            control of any matter subject
                            to indemnification by you, and you will not in any event settle any claim without the prior
                            written consent of Tax Canary.
                        </li>
                    </ol>
                </li>
            </ol>

            <h5>Article 10. Partial Invalidity</h5>
            <p>
                If, at any time, any provision of these Terms is or becomes illegal, invalid or unenforceable in any
                respect under any law of any jurisdiction, neither the legality, validity or enforceability of the
                remaining provisions nor
                the legality, validity or enforceability of such provision under the law of any other jurisdiction will
                in any way be affected or impaired.
            </p>

            <h5>Article 11. No Waiver</h5>
            <p>
                Failure of Tax Canary to insist upon or enforce strict performance of any provision of these Terms will
                not be construed as a waiver of any provision or right. No waiver of any of these Terms will be deemed a
                further or
                continuing waiver of such term or condition or any other term or condition.
            </p>

            <h5>Article 12. Territorial Restrictions</h5>

            <ol class="number">
                <li>
                    The information provided within the Service is not intended for distribution to or use by any person
                    or entity in any jurisdiction or country where such distribution or use would be contrary to law or
                    regulation or which
                    would subject Tax Canary to any registration requirement within such jurisdiction or country. We
                    reserve the right to limit the availability of the Service or any portion of the Service, to any
                    person, geographic area,
                    or jurisdiction, at any time and in our sole discretion, and to limit the quantities of any content,
                    program, product, service or other feature that Tax Canary provides
                </li>

                <li>
                    No software from the Service may be downloaded, exported or re-exported: (a) into any European Union
                    and/or U.S. embargoed countries or (b) to anyone on a sanction list maintained by the European Union
                    and/or the U.S.
                    Treasury Department's list of Specially Designated Nationals or the U.S. Department of Commerce
                    Denied Person’s List or Entity List. By downloading any software related to the Service, you
                    represent and warrant that you
                    are not located in, under the control of, or a national or resident of, any such country or on any
                    such list.
                </li>
            </ol>

            <h5>Article 13. Changes</h5>

            <ol class="number">
                <li>We reserve the right, in our sole discretion, to change these Terms ("Updated Terms") from time to
                    time.</li>
                <li>
                    Unless we make a change for legal or administrative reasons, we will provide reasonable advance
                    notice before the Updated Terms become effective. You agree that we may notify you of the Updated
                    Terms by posting them on
                    the Service, and that your use of the Service after the effective date of the Updated Terms (or
                    engaging in such other conduct as we may reasonably specify) constitutes your agreement to the
                    Updated Terms. Therefore, you
                    should review these Terms and any Updated Terms before using the Service.
                </li>
                <li>
                    The Updated Terms will be effective as of the time of posting, or such later date as may be
                    specified in the Updated Terms, and will apply to your use of the Service from that point forward.
                    These Terms will govern any
                    disputes arising before the effective date of the Updated Terms.
                </li>

                <li>These Terms are also applicable to upgrades or updates of the Service which replace and/or
                    supplement the Service, unless this update or upgrade is accompanied with Updated Terms.</li>

                <li>By using the Service, you agree that the Service may undergo upgrades and/or updates, so that Tax
                    Canary can improve, expand and develop the Service.</li>
            </ol>

            <h5>Article 14. Governing law and jurisdiction</h5>

            <ol>
                <li>These Terms, and any non-contractual obligations arising out of or in connection with it, are
                    governed by and construed in accordance with the laws of The Netherlands.</li>
                <li>
                    All disputes between you and Tax Canary (whether or not such dispute involves a third party) with
                    regard to your relationship with Tax Canary, shall be submitted exclusively to the competent court
                    of law in the
                    Netherlands in the district where Tax Canary has its registered office, without prejudice to the
                    right of Tax Canary as plaintiff to initiate proceedings before any other court having jurisdiction.
                </li>
            </ol>

            <h5>Article 15. Interpretation of these Terms</h5>
            <p>
                In case of an interpretation of the content and meaning of these Terms as well as in the case of
                conflict between the content or interpretation of any translations of these Terms and the English
                version, the English text
                shall prevail each time.
            </p>

            <p><strong> How to contact us </strong></p>
            <p>If you have any questions about these Terms, please send an email to <a
                    href="mailto:support@taxcanary.com"> support@taxcanary.com </a>.</p>

            <p><strong> Last updated </strong></p>
            <p>These Terms were last amended on 15 March 2023.</p>
        </div>
    </div>
</div>