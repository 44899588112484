import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MessagingService } from './services/messaging.service';
import { DataSharingService } from './services/data.share.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "TaxCanary";
  message:any;
  constructor(private router: Router,  private dataSharingService: DataSharingService, private messagingService: MessagingService) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  ngOnInit() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage;
    // console.log('this message', this.message)
  }


  

}
