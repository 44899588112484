import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { AdminService } from "./admin.service";
import { DataSharingService } from "./data.share.service";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(private admin: AdminService,
    private localStorage: DataSharingService,
    private router: Router
    ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    //let token = "Bearer "+ localStorage.getItem("adminToken");

    const token:any = this.localStorage.getItem('userData')
      ? JSON.parse(this.localStorage.getItem('userData') || '')
      : "";
    if (token && !req.url.includes('.pdf')) {
      req = req.clone({ headers: req.headers.set("authorization", token.userData.accessToken) });
    }

    if (this.admin.showLoader) this.admin.loader(true);
    else this.admin.showLoader = true;

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.admin.loader(false);
          }
        },
        (err: any) => {
          this.admin.loader(false);
          // console.log('....', err.error.statusCode)
          if (err instanceof HttpErrorResponse) {
            if (err.error.statusCode == 401 && ((err.error.message == 'You have been logged out') || (err.error.message == 'Your are unauthorized. Please login or create account to get access.'))) {
              this.localStorage.clear();
              this.admin.logOut();
              this.router.navigate(["/login"]);
            }

            if (err.error.statusCode == 401) {
              this.admin.errorAlert(err.error.message, 401);
            }
            // else
            //   if (err.error.statusCode == 402)
            //     this.admin.errorAlert(err.error.message, true);
            //   else
           if (err.error.statusCode == 403)
           this.admin.errorAlert(err.error.message, 403)
            else if(err.error.statusCode == 408) {
              this.admin.errorAlert(err.error.message, 408)
            }
           
  
            else{
           if(err.error.type == 'application/xml'){
              // console.log('dddddd', err.error.type)
              this.admin.showError('Invoice', 'Invoice generation is in progress, please check after some time.','');
            }else{
              this.admin.errorAlert(err.error.message, false);
            }
            
            } 

          }
        }
      )
    );
  }
}
