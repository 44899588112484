import { Component, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { DataSharingService } from "src/app/services/data.share.service";
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  localData;
  constructor(
    private location: Location,
    private dataSharingService: DataSharingService
  ) { 
    this.localData = this.dataSharingService.getItem('userData') ? JSON.parse(this.dataSharingService.getItem('userData') || '') : '';
  }

  ngOnInit(): void {
    // console.log('page')
  }

  back() {
    this.location.back();
  }

}
